import React from 'react';
import { Route } from 'react-router-dom';
import routes, { basePath } from './pages/routes';
import { AccessDenied } from './pages/AccessDenied';
import LiteraryWorks from './pages/LiteraryWorks';
import LiteraryWork from './pages/LiteraryWork';
export function createVestibularRoutes(options) {
    if (options.hasManagementPermission === false) {
        return (React.createElement(Route, { path: basePath, element: React.createElement(AccessDenied, { title: options.pages.title ?? 'Acesso Negado', message: "Voc\u00EA n\u00E3o tem permiss\u00E3o para gerenciar as obras liter\u00E1rias." }) }));
    }
    return (React.createElement(Route, { path: basePath },
        React.createElement(Route, { index: true, element: React.createElement(LiteraryWorks, { ...options.pages, ...options.pages.literaryWorks }) }),
        React.createElement(Route, { path: routes.literaryWork, element: React.createElement(LiteraryWork, { ...options.pages, ...options.pages.literaryWork }) })));
}
