import React, { useMemo, useEffect, useState } from 'react';
import { RequestStatus } from '@campus/commons';
import { AddOrEditActivityPopupContext } from '../common/addOrEditActivity/context';
import { createAddOrEditActivityPopupStore } from '../common/addOrEditActivity/store';
import Methodology from '../common/addOrEditActivity/components/Methodology';
import ActivityType from '../common/addOrEditActivity/components/ActivityType/ActivityType';
import ClassLessonAndDateList from '../common/addOrEditActivity/components/ClassLessonAndDateList';
import AddActivityButton from './components/AddActivityButton';
import { useIsMounted } from '../../../hooks';
import { times } from '../../../constants';
import { ClassroomActionsModal } from '../../Modals/ClassroomActionsModal';
import { useFetchEvaluationTypes } from '../common/addOrEditActivity/hooks/useFetchEvaluationTypes';
import { StyledTitle, StyledCancelButton } from '../common/styles';
import { StyledButtonsWrapper, StyledContentWrapper, StyledFirstColumnWrapper, StyledPopup, StyledSecondColumnWrapper } from '../common/addOrEditActivity/styles';
import { useActions } from './hooks/useActions';
const AddActivityPopup = (props) => {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const [showClassroomActionsModal, setShowClassroomActionsModal] = useState(false);
    const { isMounted } = useIsMounted();
    const abortController = useMemo(() => new AbortController(), []);
    const store = useMemo(() => {
        if (!props.canBeDisplayed)
            return null;
        return createAddOrEditActivityPopupStore({
            sequence: null,
            planning: props.planning,
            abortSignal: abortController.signal
        });
    }, [abortController.signal, props.canBeDisplayed, props.planning]);
    useFetchEvaluationTypes(props.disciplines, store, abortController.signal);
    const actions = useActions({
        store,
        abortSignal: abortController.signal,
        pedagogicalPlanningId: props.planning.id,
        newActivityIsUnforeseen: props.newActivityIsUnforeseen,
        setRequestStatus,
        handleActivityAdded: props.handleActivityAdded,
        hideModalAfterDelay() {
            setTimeout(() => {
                if (isMounted()) {
                    props.hide();
                }
            }, times.toHideAModalOrPopupOnSuccess);
        }
    });
    useEffect(() => {
        setRequestStatus(RequestStatus.NotStarted);
    }, [props.canBeDisplayed]);
    useEffect(() => {
        return () => abortController.abort();
    }, [abortController]);
    useEffect(() => {
        const state = store?.getState();
        state?.setClassesAndInfos(props.classroomGroups.data, props.classroomGroups.status);
    }, [store, props.classroomGroups.data, props.classroomGroups.status]);
    async function onAddButtonClick() {
        if (await actions.isThereAnyClassroomLinkedToOtherActivities()) {
            setShowClassroomActionsModal(true);
        }
        else {
            await actions.justAddActivity();
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledPopup, { isFullScreen: true, handleExternalClick: props.hide, canBeDisplayed: props.canBeDisplayed && !showClassroomActionsModal },
            React.createElement(AddOrEditActivityPopupContext.Provider, { value: store },
                React.createElement(StyledTitle, null, "Adicionar Atividade"),
                React.createElement(StyledContentWrapper, null,
                    React.createElement(StyledFirstColumnWrapper, null,
                        React.createElement(ActivityType, null),
                        React.createElement(ClassLessonAndDateList, null)),
                    React.createElement(StyledSecondColumnWrapper, null,
                        React.createElement(Methodology, null))),
                React.createElement(StyledButtonsWrapper, null,
                    React.createElement(StyledCancelButton, { onClick: props.hide }, "Cancelar"),
                    React.createElement(AddActivityButton, { status: requestStatus, onClick: onAddButtonClick })))),
        React.createElement(ClassroomActionsModal, { show: showClassroomActionsModal, hide: () => setShowClassroomActionsModal(false), onJustAddActivity: actions.justAddActivity, onAddActivityAndUpdateOtherOnes: actions.addActivityAndUpdateOtherOnes, onCancel: () => {
                abortController.abort();
                props.hide();
            } })));
};
export default AddActivityPopup;
